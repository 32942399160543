import styled from 'styled-components';
import { CrioTooltip } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import { Fade } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

const StyleIcon = styled('image')`
  .alert {
    height: 16px;
    width: 16px;
    color: rgb(191, 194, 195);
  }
  
  .tooltip {
    margin-left: 16px;
  }
  
  .multi {
    margin-left:16px;
    margin-right: -32px;
  }
  
  .multiAlert {
    height: 16px;
    width: 16px;
    color: rgb(191, 194, 195);
    margin-bottom: -5px;
  }
`;

type AlertIconProps = {
  visitIsPreview?: boolean;
  commentRequired?: boolean;
  multiGrid?: boolean; // represents whether the icon is showing in a multi select grid
  text?: string; // represents the text that the alert icon will show
};

/**
 * Adds in the alert icon next to a single select/multi select question (used in grid type questions).
 * @param visitIsPreview represents whether the visit is in preview mode
 * @param text represents the alert text that is shown when hovering over icon
 * @param commentRequired represents whether a comment is required for this question (helps with formatting)
 * @param multiGrid represents whether the alert icon showing here is part of a multigrid procedure
 * @constructor
 */
function AlertIcon({
  visitIsPreview, text, commentRequired, multiGrid,
}: AlertIconProps) {
  return (
    <>
      {text && visitIsPreview
            && (
            <StyleIcon>
              <CrioTooltip
                className={commentRequired ? 'tooltip' : multiGrid ? 'multi' : undefined}
                title={text}
                placement="top"
                arrow
                slots={{
                  transition: Fade,
                }}
                type="HOVER"
              >
                <StyleIcon>
                  <FontAwesomeIcon className={multiGrid ? 'multiAlert' : 'alert'} icon={faCircleExclamation} />
                </StyleIcon>
              </CrioTooltip>
            </StyleIcon>
            )}
    </>
  );
}

AlertIcon.defaultProps = {
  visitIsPreview: false,
  commentRequired: false,
  multiGrid: false,
  text: undefined,
};

export default AlertIcon;
