enum VisitType {
  SCREENING = 'SCREENING',
  TREATMENT = 'TREATMENT',
  POST_TREATMENT = 'POST_TREATMENT',
  AD_HOC = 'AD_HOC',
  SCREENING_BLOCK_VISIT = 'SCREENING_BLOCK_VISIT',
  TREATMENT_BLOCK_VISIT = 'TREATMENT_BLOCK_VISIT',
  PRE_SCREENING = 'PRE_SCREENING',
}

export default VisitType;
