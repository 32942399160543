import {
  Fade, TableBody, TableCell, TableRow,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Interweave } from 'interweave';
import { CrioRadioButton } from '@crio/crio-react-component/dist/cjs/components/Inputs/RadioGroup/RadioGroup';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { CrioTooltip } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import {
  CollapsibleText, Column, InfoText, Row,
} from '../..';
import ProcedureContext from '../../../context/ProcedureContext';
import { Answer, DataPoint, QuestionInterface } from '../../../types';
import { getAnswerCommentsJson, parseAnswerCommentJson } from '../../../util/answerComments';
import { AnswerType, QuestionFixedAnswerType, VisitModeType } from '../../../enums';
import NotDoneQuestion from './NotDoneQuestion';
import DataListQuestion from './DataListQuestion';
import HeartAndTargetIcons from '../HeartAndTargetIcons';
import AlertIcon from '../../AlertIcon';

interface SingleSelectGridProps {
  questions: Array<QuestionInterface>,
  options: Array<string>,
  renderGrid: Function,
  renderToggleAlert: Function,
  versionId?: string,
}

const VariableName = styled('span')`
  display: block;
  color: ${(props) => props.theme.palette.grey[600]};
  font-size: 0.8em;
  padding-bottom: 15px;
`;

const StyleIcon = styled('image')`
  .icon {
    height: 16px;
    width: 16px;
    color: rgb(191, 194, 195);
  }
`;

/**
 * Grid component that handles rendering multiple rows of single select questions.
 * @param props
 * @constructor
 */
function SingleSelectGrid(props: SingleSelectGridProps) {
  const [optionToggled, setOptionToggled] = useState<string>();
  const [showToggleAlert, setShowToggleAlert] = useState<boolean>(false);
  const [toggleAlertTitle, setToggleAlertTitle] = useState<string>();
  const [toggleAlertMessage, setToggleAlertMessage] = useState<string>();
  const {
    options, questions, renderGrid, renderToggleAlert, versionId,
  } = props;
  const { t } = useTranslation();
  const { studyId } = useParams();
  const {
    handleAnswerChange, records, procedureId, handleToggleAllAnswers, isLoading, readOnly, visitMode, visitType,
  } = useContext(ProcedureContext);

  if (!records[procedureId]) {
    return null;
  }
  Object.keys(records[procedureId].record).forEach((questionId) => {
    records[procedureId].record[questionId].answer_type = AnswerType.BULLET;
  });

  const handleClick = (event: any, variableName: string, questionId: string) => {
    const { target: { value: selectedOption } } = event;
    const { record } = records[procedureId];
    const dataPoint = record[questionId];

    const newAnswer = selectedOption === dataPoint.answer ? '' : selectedOption;
    handleAnswerChange({
      questionId,
      variableName,
      newDataPoint: {
        ...dataPoint,
        answer: newAnswer,
      },
      selectionValueChange: newAnswer,
    });
  };

  const handleCommentChange = (newDataPoint: DataPoint) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { question_variable_name: variableName, question_id } = newDataPoint;
    handleAnswerChange({
      questionId: question_id,
      variableName,
      newDataPoint,
      runRules: false,
    });
  };

  const isOptionSelectedForAllQuestions = (option: string | undefined) => {
    const dataPoints = records[procedureId].record;
    const values = Object.keys(dataPoints).map((questionId) => dataPoints[questionId].answer);
    return values.length !== 0 && values.every((value) => value === option);
  };
  const toggleAllForAnswerOption = (option: string, availableAnswerOptions: string[]) => {
    handleToggleAllAnswers(option, availableAnswerOptions, !isOptionSelectedForAllQuestions(option));
  };

  const handleAlertToggle = (option: string, availableAnswerOptions: string[]) => {
    setShowToggleAlert(false);
    toggleAllForAnswerOption(option, availableAnswerOptions);
  };
  const tableHeaderContent = (
    <>
      {options.map((option) => (
        <TableCell
          key={option}
          align="center"
          data-testid={`${option}-header`}
        >
          <Column
            className="GridHeader"
            title={isOptionSelectedForAllQuestions(option) ? t('Procedure.Grid.Deselect all') : t('Procedure.Grid.Select all')}
          >
            <CrioRadioButton
              disabled={readOnly}
              onClick={() => {
                setOptionToggled(option);
                setToggleAlertTitle(isOptionSelectedForAllQuestions(option) ? t('Procedure.Grid.Deselect all') : t('Procedure.Grid.Select all'));
                setToggleAlertMessage(isOptionSelectedForAllQuestions(option) ? t('Procedure.Grid.Do you want to deselect all') : t('Procedure.Grid.Do you want to select all'));
                setShowToggleAlert(true);
              }}
              checked={isOptionSelectedForAllQuestions(option)}
            />
            <span className="GridLabel">{option}</span>
            <Row>
              {questions.at(0)!.answerOptions!.at(options.indexOf(option))!.commentRequired && visitMode === VisitModeType.PROCEDURE_PREVIEW
                && (
                <CrioTooltip
                  title={t('Icon.Provide Comment')}
                  placement="top"
                  arrow
                  slots={{
                    transition: Fade,
                  }}
                  type="HOVER"
                >
                  <StyleIcon>
                    <FontAwesomeIcon className="icon" icon={faMessageLines} />
                  </StyleIcon>
                </CrioTooltip>
                )}
              <AlertIcon
                visitIsPreview={visitMode === VisitModeType.PROCEDURE_PREVIEW}
                commentRequired={questions.at(0)!.answerOptions!.at(options.indexOf(option))!.commentRequired}
                text={questions.at(0)!.answerOptions!.at(options.indexOf(option))!.alertText}
              />
            </Row>
          </Column>
        </TableCell>
      ))}
      {showToggleAlert && renderToggleAlert(
        showToggleAlert,
        setShowToggleAlert,
        optionToggled,
        options,
        handleAlertToggle,
        toggleAlertTitle,
        toggleAlertMessage,
        t,
      )}
    </>
  );

  const getTableBody = () => {
    const tableBody: JSX.Element[] = [];
    questions.forEach(({
      answerOptions, hint, questionId, questionText, variableName, order, type, templateLevel,
    }) => {
      let showTextField = false;
      const dataPoint = records[procedureId].record[questionId];
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        answer, answer_comment, is_disabled,
      } = dataPoint;

      if (!is_disabled) {
        tableBody.push(
          <TableBody data-testid={`question-${variableName}`} key={questionId}>
            <TableRow>
              <TableCell className="GridLabel LeftCell">
                <Interweave content={questionText} />
                <HeartAndTargetIcons
                  visitIsPreview={visitMode === VisitModeType.PROCEDURE_PREVIEW}
                  core={templateLevel === 'IN_TEMPLATE_CORE'}
                  siteAdded={templateLevel === 'NOT_FROM_TEMPLATE' && versionId !== undefined}
                />
                {visitMode === VisitModeType.PROCEDURE_PREVIEW && <VariableName>{variableName}</VariableName>}
              </TableCell>
              {(answer === QuestionFixedAnswerType.NOT_DONE)
                ? (
                  <TableCell colSpan={answerOptions!.length}>
                    <NotDoneQuestion
                      dataPoint={dataPoint}
                      handleAnswerChange={handleAnswerChange}
                      isLoading={isLoading}
                      questionText={questionText}
                      questionId={questionId}
                      variableName={variableName}
                      order={order}
                      type={type}
                      visitType={visitType}
                    />
                  </TableCell>
                )
                : (answerOptions!.map(({ commentRequired, text: optionText }, colIndex) => {
                  const isChecked = answer === optionText;
                  if (commentRequired && isChecked) {
                    showTextField = true;
                  }
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableCell data-testid={`answer-${optionText}`} key={colIndex} align="center">
                      <CrioRadioButton
                        disabled={readOnly}
                        name={variableName}
                        checked={isChecked}
                        value={optionText}
                        onClick={(e) => handleClick(e, variableName!, questionId)}
                      />
                    </TableCell>
                  );
                }))}
            </TableRow>
            {(showTextField || hint)
              ? (
                <TableRow className="HintAndCommentRow">
                  <TableCell className="LeftCell">
                    {hint && (<InfoText><CollapsibleText data-testid="instructionText" text={hint} numLines={1} /></InfoText>)}
                  </TableCell>
                  <TableCell colSpan={options.length}>
                    {showTextField && (
                      (() => {
                        const selectedOption: Answer | undefined = (answer && answerOptions) ? (answerOptions.find(({ text }) => text === answer)) : undefined;
                        const { dataList: { externalId = undefined, isRestrictedToDataList = undefined } = {} } = selectedOption || {};
                        const currentComment = parseAnswerCommentJson(answer_comment, answer) || '';

                        return (
                          <DataListQuestion
                            data-testid="addCommentText"
                            variableName={variableName!}
                            studyId={studyId!}
                            dataListExternalId={externalId}
                            restrictedToDataList={isRestrictedToDataList}
                            readOnly={readOnly}
                            placeholder={t('Common.Enter Comments')}
                            fullWidth
                            multiline={!externalId}
                            value={currentComment}
                            onBlur={(newComment: string) => {
                              handleCommentChange({
                                ...dataPoint,
                                answer_comment: getAnswerCommentsJson(newComment, answer),
                              });
                            }}
                          />
                        );
                      })()
                    )}
                  </TableCell>
                </TableRow>
              ) : undefined}
          </TableBody>,
        );
      }
    });
    return tableBody;
  };

  const tableFooterContent = options.map((option, index) => {
    let totalSelected = 0;
    const dataPoints = records[procedureId].record;
    const values = Object.keys(dataPoints).map((questionId) => dataPoints[questionId].answer);
    values.forEach((value) => {
      if (value === option) totalSelected += 1;
    });
    // eslint-disable-next-line react/no-array-index-key
    return <TableCell data-testid={`${option}-selectCount`} key={index} align="center"><span className="GridLabel">{totalSelected}</span></TableCell>;
  });

  return renderGrid(tableHeaderContent, getTableBody(), tableFooterContent);
}

export default SingleSelectGrid;
