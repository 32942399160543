import {
  Box, ClickAwayListener, Stack, styled, ThemeProvider,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faEllipsisV, faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import { useContext, useState } from 'react';
import { CrioTooltip } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Column, Row } from '..';
import { formatDateTime } from '../../util/dateTimeUtil';
import ProgressNoteStatus from '../../enums/ProgressNoteStatus';
import { toTitleCase } from '../../util/stringUtil';
import ProgressNoteContext from '../../context/ProgressNoteContext';
import { ProgressNoteInterface } from '../../types';
import { SlideoutState } from '../../enums';
import progressNoteImg from '../../assets/progress_note.png';
import SmallLoadingSpinner from '../SmallLoadingSpinner';

const StyledProgressNote = styled(Row)`
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 1px dotted ${(props) => props.theme.palette.grey[500]};
  }

  .NoteContent {
    justify-content: center;
    .Note {
      overflow-wrap: anywhere;
      font-size: 0.875em;
      font-family: ${(props) => `"Poppins Light", ${props.theme.typography.fontFamily}`};
      color: ${(props) => props.theme.palette.grey[900]};
    }

    .Timestamp {
      color: ${(props) => props.theme.palette.grey[600]};
      font-size: 0.8em;
      font-family: ${(props) => `"Poppins Light", ${props.theme.typography.fontFamily}`};
    }
  }

  .Icons {
    padding-top: 5px;
    align-content: flex-start;
    min-width: 10%;

    svg {
      max-width: fit-content;
    }
  }

  .Options {
    .MuiTooltip-tooltip {
      border-radius: 10px;
      padding: 0;
    }
    justify-content: center;
    padding-left: 15px;
    padding-right: 5px;
    margin-left: auto;
    z-index: 1;
  }

  .OptionsTooltip {
    min-width: 80px;
    > div {
      &:first-of-type {
        padding-top: 12px;
      }
      &:last-of-type {
        padding-bottom: 12px;
      }
      padding: 6px 20px;
      cursor: pointer;
      font-size: .9em;
      font-family: ${(props) => `"Poppins Light", ${props.theme.typography.fontFamily}`};
      font-weight: ${(props) => props.theme.typography.fontWeightRegular};
      color: ${(props) => props.theme.palette.grey[700]};
      :hover {
        background-color:  #FCF1E9;
        &:first-of-type {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        &:last-of-type {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  .EllipsisIcon {
    color: ${(props) => props.theme.palette.grey[500]};
    font-size: 1.15em;
    transform: scaleY(1.1);
  }

  .FileIcon {
    padding-bottom: 5px;
    color: ${(props) => props.theme.palette.info.dark};

    &.Draft {
      color: #b1e3ff;
    }

    &.Signed {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }

  .Status-Notifier {
    margin-top: 3px;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.palette.grey[600]};
    font-size: 0.8em;
  }

  &.VisitLevel {
    border-bottom: none;
    padding: 10px 5px 10px 20px;
    margin: 10px 105px 10px 0;
    width: unset;
    .Icons {
      align-items: center;
      padding-right: 20px;
    }
    .Status-Notifier {
      font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    }
    &:hover {
      background-color: ${(props) => props.theme.palette.grey[100]};
    }
  }
`;

const StyledNoProgressNotes = styled(Column)`
  align-items: center;
  padding-top: 60px;
  color: ${(props) => props.theme.palette.grey[600]};
  font-size: .9em;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  img {
    padding-bottom: 15px;
  }
`;

const CustomSmallLoadingSpinner = styled(SmallLoadingSpinner)`
  margin-right: 31px;
`;

const StyledErrorMessage = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  
  .alert-icon {
    color: ${(props) => props.theme.palette.error.main};
  }
  
  .error-text {
    color: ${(props) => props.theme.palette.grey[900]};
    font-size: .875rem;
    margin-left: 5px;
    margin-right: 21px;
  }
`;

type ProgressNoteProps = {
  progressNote: ProgressNoteInterface;
  readOnly: boolean;
  handleProgressNoteToEditChange: (progressNote: ProgressNoteInterface, callback?: () => void) => void;
  handleSlideoutStateChange: (slideoutState: SlideoutState) => void;
  isVisitLevel: boolean;
  timeZone?: string;
};

function ProgressNote({
  progressNote, readOnly, handleProgressNoteToEditChange, handleSlideoutStateChange, isVisitLevel, timeZone,
}: ProgressNoteProps) {
  const { t } = useTranslation();
  const { note, date_created, status } = progressNote;
  const { handleUpdateProgressNote, handleDeleteProgressNote } = useContext(ProgressNoteContext);
  // the purpose of this is to refresh the tooltip and make it disappear when an option is selected
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleKickoffEditProgressNote = () => {
    handleProgressNoteToEditChange(progressNote);
    handleSlideoutStateChange(SlideoutState.EDIT_PROGRESS_NOTES);
  };

  const handlePublishNote = () => {
    handleUpdateProgressNote(progressNote, undefined, ProgressNoteStatus.PUBLISHED);
  };

  const handleDeleteNote = () => {
    handleDeleteProgressNote(progressNote);
  };

  return (
    <StyledProgressNote className={isVisitLevel ? 'VisitLevel' : ''}>
      <Column className="Icons">
        <FontAwesomeIcon
          icon={faFileAlt as IconProp}
          className={`FileIcon ${toTitleCase(status)}`}
          size="lg"
        />
        {isVisitLevel && <div className="Status-Notifier">{status === ProgressNoteStatus.SIGNED ? 'ESIGNED' : status}</div>}
      </Column>
      <Column className="NoteContent">
        <div className="Note">{note}</div>
        <div className="Timestamp">{formatDateTime(date_created, timeZone)}</div>
        {!isVisitLevel && status === ProgressNoteStatus.SIGNED && <div className="Status-Notifier">{t('Procedure.Progress Note.ESIGNED')}</div>}
      </Column>
      {status !== ProgressNoteStatus.SIGNED && !readOnly && status !== ProgressNoteStatus.PUBLISHED
        && (
          <Column
            className="Options"
          >
            <CrioTooltip
              open={showTooltip}
              type="CLICK"
              arrow
              placement="left"
              title={(
                <Stack className="OptionsTooltip" direction="column">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={handlePublishNote}
                    onKeyDown={handlePublishNote}
                  >
                    {t('Common.Publish')}
                  </div>
                  <div
                    data-testid="edit"
                    role="button"
                    tabIndex={0}
                    onClick={handleKickoffEditProgressNote}
                    onKeyDown={handleKickoffEditProgressNote}
                  >
                    {t('Common.Edit')}
                  </div>
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={handleDeleteNote}
                    onKeyDown={handleDeleteNote}
                  >
                    {t('Common.Delete')}
                  </div>
                </Stack>
              )}
            >
              <>
                <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
                  <Box>
                    <FontAwesomeIcon
                      data-testid="ellipsis"
                      icon={faEllipsisV as IconProp}
                      className="EllipsisIcon"
                      onClick={() => setShowTooltip(true)}
                    />
                  </Box>
                </ClickAwayListener>
              </>
            </CrioTooltip>
          </Column>
        )}
    </StyledProgressNote>
  );
}

type ProcedureProgressNotesProps = {
  readOnly: boolean;
  handleProgressNoteToEditChange: (progressNote: ProgressNoteInterface, callback?: () => void) => void;
  handleSlideoutStateChange: (slideoutState: SlideoutState) => void;
  isVisitLevel: boolean;
  timeZone?: string;
};
export default function ProgressNotes({
  readOnly,
  handleProgressNoteToEditChange,
  handleSlideoutStateChange,
  isVisitLevel,
  timeZone,
}: ProcedureProgressNotesProps) {
  const { t } = useTranslation();
  const { progressNotes, isLoading, isError } = useContext(ProgressNoteContext);

  const getProgressNoteComponent = () => {
    if (isError) {
      return (
        <StyledErrorMessage>
          <FontAwesomeIcon className="alert-icon" icon={faCircleExclamation} />
          <Typography className="error-text" align="center">{t('Procedure.Progress Note.Failed to load')}</Typography>
        </StyledErrorMessage>
      );
    }
    if (isLoading) {
      return <CustomSmallLoadingSpinner />;
    }
    if (progressNotes.length === 0 && isVisitLevel) {
      return (
        <StyledNoProgressNotes data-testid="no-progress-notes">
          <img src={progressNoteImg} alt="Note" width="150px" />
          {t('Procedure.Progress Note.None')}
        </StyledNoProgressNotes>
      );
    }
    return progressNotes.map((progressNote, index) => (
      <ProgressNote
        // TODO: this key has to remain as index until a time where externalId is always on progressNote
        key={index}
        progressNote={progressNote}
        readOnly={readOnly}
        handleProgressNoteToEditChange={handleProgressNoteToEditChange}
        handleSlideoutStateChange={handleSlideoutStateChange}
        isVisitLevel={isVisitLevel}
        timeZone={timeZone}
      />
    ));
  };

  return (
    <ThemeProvider theme={crioTheme}>
      <Column>
        {getProgressNoteComponent()}
      </Column>
    </ThemeProvider>
  );
}

ProgressNote.defaultProps = {
  timeZone: 'US/Eastern',
};

ProgressNotes.defaultProps = {
  timeZone: 'US/Eastern',
};
