import {
  Fade, styled, TableBody, TableCell, TableRow,
} from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import { Interweave } from 'interweave';
import { CrioCheckbox } from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioCheckboxGroup';
import { CrioTooltip } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment as faCommentSolid, faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { faComment as faCommentOutline } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  CollapsibleText, Column, InfoText,
} from '../..';
import ProcedureContext from '../../../context/ProcedureContext';
import { DataPoint, QuestionInterface } from '../../../types';
import { addToAnswerCommentsJson, parseAnswerCommentJson } from '../../../util/answerComments';
import { AnswerType, QuestionFixedAnswerType, VisitModeType } from '../../../enums';
import NotDoneQuestion from './NotDoneQuestion';
import DataListQuestion from './DataListQuestion';
import { updateAndSortMultiSelectAnswers } from '../../../util/dataPointUtil';
import HeartAndTargetIcons from '../HeartAndTargetIcons';
import AlertIcon from '../../AlertIcon';

const StyledTableCell = styled(TableCell)`
  text-align: center;
  padding-top: 18px;
  padding-bottom: 0px;

  .MuiCheckbox-root {
    margin-bottom: 2.5px !important;
  }

  .stub {
    padding-top: 23.94px;
  }
`;

const VariableName = styled('span')`
  display: block;
  color: ${(props) => props.theme.palette.grey[600]};
  font-size: 0.8em;
  padding-bottom: 15px;
`;

const StyleIcon = styled('image')`
  .icon {
    height: 16px;
    width: 16px;
    color: rgb(191, 194, 195);
  }
  
  .alert {
    margin-top: 16px;
  }
`;

interface MultiSelectGridProps {
  questions: Array<QuestionInterface>,
  options: Array<string>,
  renderGrid: Function,
  renderToggleAlert: Function,
  versionId?: string,
}

/**
 * Grid component that handles rendering multiple rows of multi select questions.
 * @param props
 * @constructor
 */
function MultiSelectGrid(props: MultiSelectGridProps) {
  const {
    options, questions, renderGrid, renderToggleAlert, versionId,
  } = props;
  const { t } = useTranslation();
  const { studyId } = useParams();
  const {
    handleAnswerChange, procedureId, records, handleToggleAllAnswers, isLoading, readOnly, visitMode, visitType,
  } = useContext(ProcedureContext);

  const [optionToggled, setOptionToggled] = useState<string>();
  const [showToggleAlert, setShowToggleAlert] = useState<boolean>(false);
  const [toggleAlertTitle, setToggleAlertTitle] = useState<string>();
  const [toggleAlertMessage, setToggleAlertMessage] = useState<string>();

  if (!records[procedureId]) {
    return null;
  }
  Object.keys(records[procedureId].record).forEach((questionId) => {
    records[procedureId].record[questionId].answer_type = AnswerType.BULLET;
  });

  /**
   * Handle changes to the datapoints (i.e. users clicking off different boxes in the grid)
   * @param event The change event
   * @param variableName  The variableName that is changing
   * @param questionId The questionId that is changing
   * @param changedOptionValue The specific option value that has changed
   * @param answerOptions the answer options so we can sort the selected values
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>, variableName: string, questionId: string, changedOptionValue: string, answerOptions: string[]) => {
    const { target: { value: selectedOption, checked } } = event;
    const dataPoint = records[procedureId].record[questionId];
    const newDataPoint = {
      ...dataPoint,
      answer: updateAndSortMultiSelectAnswers(
        (dataPoint.answer as string) || '',
        selectedOption,
        answerOptions,
        checked,
      ),
    };

    handleAnswerChange({
      questionId,
      variableName,
      newDataPoint,
      selectionValueChange: checked ? changedOptionValue : '',
    });
  };

  const handleCommentChange = (newDataPoint: DataPoint) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { question_variable_name: variableName, question_id } = newDataPoint;
    handleAnswerChange({
      questionId: question_id,
      variableName,
      newDataPoint,
      runRules: false,
    });
  };

  const isOptionSelectedForAllQuestions = (option: string | undefined) => {
    if (!option) return false;
    let allSelected = false;

    for (let i = 0; i < questions.length; i += 1) {
      const { questionId } = questions[i];
      const { answer = '' } = records[procedureId].record[questionId];
      const optionsSelected = ((answer as string) || '').split('\n');
      const isOptionSelected = optionsSelected.includes(option);
      if (!isOptionSelected) break;
      if (i === questions.length - 1) {
        allSelected = isOptionSelected;
      }
    }
    return allSelected;
  };

  const toggleAllForAnswerOption = (option: string, availableAnswerOptions: string[]) => {
    handleToggleAllAnswers(option, availableAnswerOptions, !isOptionSelectedForAllQuestions(option), true);
  };

  const handleAlertToggle = (option: string, availableAnswerOptions: string[]) => {
    setShowToggleAlert(false);
    toggleAllForAnswerOption(option, availableAnswerOptions);
  };

  const tableHeaderContent = (
    <>
      {options.map((option) => (
        <TableCell
          key={option}
          align="center"
          data-testid={`${option}-header`}
        >
          <Column className="GridHeader" title={isOptionSelectedForAllQuestions(option) ? t('Procedure.Grid.Deselect all') : t('Procedure.Grid.Select all')}>
            <CrioCheckbox
              disabled={readOnly}
              onChange={() => {
                setOptionToggled(option);
                setToggleAlertTitle(isOptionSelectedForAllQuestions(option) ? t('Procedure.Grid.Deselect all') : t('Procedure.Grid.Select all'));
                setToggleAlertMessage(isOptionSelectedForAllQuestions(option) ? t('Procedure.Grid.Do you want to deselect all') : t('Procedure.Grid.Do you want to select all'));
                setShowToggleAlert(true);
              }}
              checked={isOptionSelectedForAllQuestions(option)}
            />
            <span className="GridLabel">{option}</span>
            {questions.at(0)!.answerOptions!.at(options.indexOf(option))!.commentRequired && visitMode === VisitModeType.PROCEDURE_PREVIEW
                && (
                <CrioTooltip
                  title={t('Icon.Provide Comment')}
                  placement="top"
                  arrow
                  slots={{
                    transition: Fade,
                  }}
                  type="HOVER"
                >
                  <StyleIcon>
                    <FontAwesomeIcon className="icon" icon={faMessageLines} />
                  </StyleIcon>
                </CrioTooltip>
                )}
          </Column>
        </TableCell>
      ))}
      {showToggleAlert && renderToggleAlert(
        showToggleAlert,
        setShowToggleAlert,
        optionToggled,
        options,
        handleAlertToggle,
        toggleAlertTitle,
        toggleAlertMessage,
        t,
      )}
    </>
  );

  const getTableBody = () => {
    const tableBody: JSX.Element[] = [];
    questions.forEach(({
      answerOptions, hint, questionText, variableName, questionId, order, type, templateLevel,
    }, index) => {
      const dataPoint = records[procedureId].record[questionId];
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { answer = '', answer_comment = '{}', is_disabled } = dataPoint;
      const answerArray = ((answer as string) || '').split('\n');
      const answerOptionArray = (answerOptions || []).map(({ text }) => text);

      if (!is_disabled) {
        tableBody.push(
          // eslint-disable-next-line react/no-array-index-key
          <TableBody data-testid={`question-${variableName}`} key={index}>
            <TableRow>
              <TableCell className="GridLabel LeftCell">
                <Interweave content={questionText} />
                <HeartAndTargetIcons
                  visitIsPreview={visitMode === VisitModeType.PROCEDURE_PREVIEW}
                  core={templateLevel === 'IN_TEMPLATE_CORE'}
                  siteAdded={templateLevel === 'NOT_FROM_TEMPLATE' && versionId !== undefined}
                />
                {visitMode === VisitModeType.PROCEDURE_PREVIEW && <VariableName>{variableName}</VariableName>}
              </TableCell>
              {(answer === QuestionFixedAnswerType.NOT_DONE)
                ? (
                  <TableCell colSpan={answerOptions!.length}>
                    <NotDoneQuestion
                      dataPoint={dataPoint}
                      handleAnswerChange={handleAnswerChange}
                      isLoading={isLoading}
                      questionText={questionText}
                      questionId={questionId}
                      variableName={variableName}
                      order={order}
                      type={type}
                      visitType={visitType}
                    />
                  </TableCell>
                )
                : (answerOptions!.map((answerOption, colIndex) => {
                  const { commentRequired, text: optionText, alertText } = answerOption;
                  const isChecked = answerArray.includes(optionText);
                  const comment = parseAnswerCommentJson(answer_comment, optionText);
                  const commentIconStyle = (comment) ? { color: '#CCCCCC' } : { color: '#E3761C', opacity: '50%' };
                  const commentIcon = (comment) ? faCommentSolid : faCommentOutline;

                  const dataListComment = () => {
                    const dataList = answerOption?.dataList;
                    const dataListExternalId = dataList?.externalId;
                    const isRestrictedToDataList = dataList?.isRestrictedToDataList;
                    return (
                      <DataListQuestion
                        data-testid="addCommentText"
                        variableName={variableName!}
                        studyId={studyId!}
                        dataListExternalId={dataListExternalId}
                        restrictedToDataList={isRestrictedToDataList}
                        readOnly={readOnly}
                        placeholder={t('Common.Enter Comments Required')}
                        fullWidth
                        multiline={!dataListExternalId}
                        value={comment || ''}
                        onBlur={(newComment: string) => {
                          handleCommentChange({
                            ...dataPoint,
                            answer_comment: addToAnswerCommentsJson(answer_comment, newComment, optionText),
                          });
                        }}
                      />
                    );
                  };

                  return (
                    <StyledTableCell data-testid={`answer-${optionText}`} key={colIndex}>
                      <CrioCheckbox
                        disabled={readOnly}
                        name={variableName}
                        checked={isChecked}
                        value={optionText}
                        onChange={(e) => handleChange(e, variableName!, questionId, optionText, answerOptionArray)}
                      />
                      <StyleIcon className="alert">
                        <AlertIcon
                          visitIsPreview={visitMode === VisitModeType.PROCEDURE_PREVIEW}
                          text={alertText}
                          multiGrid
                        />
                      </StyleIcon>
                      {commentRequired && isChecked ? (
                        <CrioTooltip
                          data-testid="addCommentBubble"
                          type="CLICK"
                          arrow
                          placement="bottom"
                          title={dataListComment()}
                        >
                          <FontAwesomeIcon style={commentIconStyle} size="lg" icon={commentIcon as IconProp} />
                        </CrioTooltip>
                      ) : (<div className="stub" />)}
                    </StyledTableCell>
                  );
                }))}
            </TableRow>
            {(hint)
              ? (
                <TableRow className="HintAndCommentRow">
                  <TableCell className="LeftCell">
                    {hint && (
                    <InfoText><CollapsibleText data-testid="instructionText" text={hint} numLines={1} /></InfoText>)}
                  </TableCell>
                </TableRow>
              ) : undefined}
          </TableBody>,
        );
      }
    });
    return tableBody;
  };

  const tableFooterContent = options.map((option) => {
    let totalSelected = 0;
    questions.forEach(({ questionId }) => {
      const { answer = '' } = records[procedureId].record[questionId];
      const optionsSelected = ((answer as string) || '').split('\n');
      if (optionsSelected.includes(option)) totalSelected += 1;
    });

    return <TableCell data-testid={`${option}-selectCount`} key={option} align="center"><span className="GridLabel">{totalSelected}</span></TableCell>;
  });

  return renderGrid(tableHeaderContent, getTableBody(), tableFooterContent);
}

export default MultiSelectGrid;
