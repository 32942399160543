import styled from 'styled-components';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ProcedureInterface } from '../../types';
import { ProcedureQuestion } from './ProcedureQuestion';
import ProcedureContext from '../../context/ProcedureContext';
import { Row } from '..';
import { VisitModeType } from '../../enums';

const StyledRow = styled(Row)`
  .clearButton {
    color: ${(props) => props.theme.palette.linkText.main};
    cursor: pointer;
    padding-left: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

function NormalProcedure(props: ProcedureInterface) {
  const { questions, versionId } = props;
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    clearProcedure, saveProcedure, handleAnswerChange: handleAnswerChangeInContext, records, referencedRecords, procedureId, isLoading, readOnly, proceedToNextProcedure, rulesProcessing, visitMode, visitType,
  } = useContext(ProcedureContext);

  // This is a little hack to ensure that the buttons on the bottom don't flash before the list of questions is
  // done rendering. The buttons would show for a tiny bit and then get pushed to the bottom of the page. This
  // hack will put the buttons render on the next render cycle
  useEffect(() => {
    if (!isLoading) {
      setShowButtons(true);
    }
  }, [isLoading]);

  return (
    <>
      {questions.map(({
        answerOptions,
        core,
        formatType,
        hint,
        order,
        variableName,
        questionId,
        questionText,
        type,
        dataListExternalId,
        restrictedToDataList,
        criteria,
        templateLevel,
      }) => {
        /*
          Anonymous functions will always get a new reference on each rerender, therefore triggering a rerender
          of any children regardless of if they're memoized or not. Memoize the function itself with useCallback
          as we don't want EVERY ProcedureQuestion to rerender on every change. The dependency array is dataPoint.
          This because we actually DO need a new reference when the specific datapoint changes or else the state
          inside the handleAnswerChangeInContext closure will contain stale state.
        */
        const dataPoint = records[procedureId] ? records[procedureId].record[questionId] : undefined;
        const referenceDataPoint = (referencedRecords && referencedRecords[procedureId]) ? referencedRecords[procedureId].record[questionId] : undefined;
        const handleAnswerChange = useCallback(((args: any) => {
          handleAnswerChangeInContext(args);
        }), [dataPoint]);

        return (
          <ProcedureQuestion
            key={`null:${questionId}`}
            questionId={questionId}
            questionText={questionText}
            variableName={variableName}
            hint={hint}
            criteria={criteria}
            order={order}
            type={type}
            formatType={formatType}
            core={core}
            answerOptions={answerOptions}
            dataPoint={dataPoint}
            referencedDataPoint={referenceDataPoint}
            handleAnswerChange={handleAnswerChange}
            isLoading={isLoading}
            readOnly={readOnly}
            showVariableName={visitMode === VisitModeType.PROCEDURE_PREVIEW}
            dataListExternalId={dataListExternalId}
            restrictedToDataList={restrictedToDataList}
            visitMode={visitMode}
            visitType={visitType}
            currentProcedureId={procedureId}
            templateLevel={templateLevel}
            versionId={versionId}
          />
        );
      })}
      {showButtons && (
      <StyledRow style={{ marginTop: '30px', alignItems: 'center' }}>
        {!readOnly
          ? (
            <>
              <CrioButton onClick={() => {
                saveProcedure({ successCallback: proceedToNextProcedure });
              }}
              >
                {t('Common.Save and Continue')}
              </CrioButton>
              <div
                role="button"
                tabIndex={0}
                className="clearButton"
                onClick={clearProcedure}
                onKeyDown={clearProcedure}
              >
                {t('Common.Clear')}
              </div>
            </>
          )
          : <CrioButton onClick={() => proceedToNextProcedure()}>{t('Common.Continue')}</CrioButton>}
          {!!rulesProcessing && <span data-testid="rulesProcessing" />}
      </StyledRow>
      )}
    </>
  );
}

export default NormalProcedure;
