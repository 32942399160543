import { DateTime } from 'luxon';
import sqliteParser from 'sqlite-parser';
import { removeQuotes } from '../stringUtil';

/**
 * Goes through a sqlite condition and finds all quoted sections. This will find both double and single quotes.
 * Then, replace each instance of quoted text with itself with the quote characters INSIDE removed.
 * Ex.  [var = "Parkinson's Disease" OR var = 'He said "Hello"'] -> [var = "Parkinsons Disease" OR var = 'He said Hello']
 * This is useful for not causing issues with the sqlite parser.
 * @param sqliteWhere - string containing condition
 * @returns stripped string as explained above.
 */
export const sanitizeCondition = (sqliteWhere) => {
  let newString = sqliteWhere;
  const instances = [...sqliteWhere.matchAll(/(["'“‘]).*?(?<!\\)(\\\\)*\1/g)];
  instances.forEach((i) => {
    const str = i[0].substring(1, i[0].length - 1);
    newString = newString.replace(str, removeQuotes(str));
  });
  return newString;
};

/**
 * Parses a sqlite where clause to json as it appears in our crio database.
 * @returns json for the where clause
 * @param sqliteWhere
 */
const parseSqliteStatement = (sqliteWhere) => {
  const whereExtractor = (ast) => ast.statement[0].where[0];
  return whereExtractor(sqliteParser(`select 1
                                      from procedure
                                      where ${sanitizeCondition(sqliteWhere).replace(/"/g, '\'')}`));
};
export { parseSqliteStatement };

/**
 * Parses crio arrow syntax to extract the where clause and jsonify it.
 * @param {string} sqliteWhereWithArrow
 * @returns json for the where clause
 */
const parseSqliteStatementFromArrowSyntax = (sqliteWhereWithArrow) => {
  const whereClause = sqliteWhereWithArrow.split(' -> ')[0];
  return parseSqliteStatement(whereClause);
};
export { parseSqliteStatementFromArrowSyntax };

/*
Recreated functions that clients can use (derived from SQLite Functions originally)
*/
const functions = {
  length: (string) => (string ? string.length : 0),
  // TODO .replace with regexp or see about replaceAll as an option?
  replace: (string, strToReplace, strToReplaceWith) => {
    if (!string) return string;
    const regexp = typeof strToReplace === 'string' ? new RegExp(strToReplace, 'g') : strToReplace;
    return string.replace(regexp, strToReplaceWith);
  },
  match: (string, regexp) => string && string.match(regexp) !== null,
  addTime: (dateTimeInput, mod) => {
    const dateTimeOutput = dateTimeInput;
    if (mod.match(/\d+\s\w/gi)) {
      const split = mod.split(' ');
      if (split[1].startsWith('day')) {
        return dateTimeOutput.plus({ days: parseInt(split[0], 10) });
      }
      if (split[1].startsWith('hour')) {
        return dateTimeOutput.plus({ hours: parseInt(split[0], 10) });
      }
      if (split[1].startsWith('minute')) {
        return dateTimeOutput.plus({ minutes: parseInt(split[0], 10) });
      }
      if (split[1].startsWith('second')) {
        return dateTimeOutput.plus({ seconds: parseInt(split[0], 10) });
      }
      if (split[1].startsWith('month')) {
        return dateTimeOutput.plus({ months: parseInt(split[0], 10) });
      }
      if (split[1].startsWith('year')) {
        return dateTimeOutput.plus({ years: parseInt(split[0], 10) });
      }
    }
    return dateTimeOutput;
  },
  datetime: (input, ...mods) => {
    if (!input) return null;
    const baseDateTime = typeof input === 'string'
      ? (input === 'now'
        ? DateTime.now()
        : DateTime.fromJSDate(new Date(input.includes(':') ? input : `${input} 00:00`)))
      : input;
    return mods.reduce((acc, mod) => {
      if (mod === 'start of month') {
        return acc.startOf('month');
      }
      if (mod === 'start of year') {
        return acc.startOf('year');
      }
      if (mod === 'start of day') {
        return acc.startOf('day');
      }
      if (mod.match(/[weekday]\s\d+/gi)) {
        const dayNum = parseInt(mod.split(' ')[1], 10);
        // Sunday = 7 in luxon, but 0 in sqlite
        return acc.set({ weekday: dayNum === 0 ? 7 : dayNum }).startOf('day');
      }
      if (mod === 'utc') {
        return acc.toUTC();
      }
      if (mod === 'localtime') {
        return acc.toLocal();
      }
      // try to add time
      return functions.addTime(acc, mod);
    }, baseDateTime);
  },
  date: (input, ...mods) => functions.datetime(input, ...mods),
  time: (input, ...mods) => functions.datetime(input, ...mods),
  julianday: (input, ...mods) => {
    if (!input) return null;
    // 2440587.5 is the baseline number of juliandays
    // 86400000 is the conversion to days (24 * 60 * 60, etc.)
    return functions.datetime(input, ...mods) / 86400000 + 2440587.5;
  },
  false: () => false,
};
export { functions };

/**
 * Parses a sqlite json, assuming it's a literal type. Returns null if no recognized type is found
 * @param json
 * @param replaceWildcards
 * @returns {string|RegExp|number}
 */
const convertSqliteJsonLiteral = (json, replaceWildcards = false) => {
  if (json.variant === 'date' && json.value === 'current_date') {
    return 'functions.datetime(\'now\')';
  }
  if (json.variant === 'decimal') {
    return parseFloat(json.value);
  }
  if (json.variant === 'null') {
    return 'null';
  }

  if (replaceWildcards) {
    if (json.value.includes('%')) {
      return new RegExp(`^${json.value.replace(/%/g, '[\\s\\S]*')}$`, 'g');
    }
    return new RegExp(`^${json.value}$`, 'g');
  }

  if (json.variant === 'text') {
    // do NOT remove this escaping - it will break the sqlite parser
    // eslint-disable-next-line
    return `\"${json.value}\"`;
  }
  return 'null';
};

/**
 * Converts json ast from sqlite-parser library back to a conditional,
 * with modifications for use in js function
 * @param {json} json
 * @param replaceWildcards whether to replace %
 * @returns a string where clause to be executed for a js function
 */
const convertSqliteJsonForNodeRules = (json, replaceWildcards = false) => {
  if (json.type === 'expression') {
    if (json.variant === 'list') {
      return json.expression.map((listItem) => convertSqliteJsonForNodeRules(listItem, replaceWildcards));
    }
    if (json.variant === 'case') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return convertSqliteJsonCase(json);
    }
    if (json.variant === 'operation') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return convertSqliteJsonOperation(json, replaceWildcards);
    }
  } else if (json.type === 'function') {
    return `functions.${convertSqliteJsonForNodeRules(json.name, replaceWildcards)}(${convertSqliteJsonForNodeRules(json.args, replaceWildcards)})`;
  } else if (json.type === 'identifier') {
    if (json.variant === 'column') {
      return `fact.${json.name}`;
    }
    if (json.variant === 'function' && functions.hasOwnProperty(json.name.toLowerCase())) {
      return json.name.toLowerCase();
    }
  } else if (json.type === 'literal') {
    return convertSqliteJsonLiteral(json, replaceWildcards);
  }
  return 'false';
};
export { convertSqliteJsonForNodeRules };

/**
 * Parse a sqlite json, assuming it's a case type.
 * @param json
 */
const convertSqliteJsonCase = (json) => {
  const { discriminant, expression } = json;
  const elseCase = expression.find((condition) => condition.variant === 'else') || { consequent: parseSqliteStatement('null') };
  const baseCase = convertSqliteJsonForNodeRules(elseCase.consequent);
  const cases = expression.reduce((casesThusFar, condition) => {
    if (condition.variant === 'when') {
      return discriminant
        ? `(${convertSqliteJsonForNodeRules(discriminant)} === ${convertSqliteJsonForNodeRules(condition.condition)}) ? ${convertSqliteJsonForNodeRules(condition.consequent)} : ${casesThusFar}`
        : `(${convertSqliteJsonForNodeRules(condition.condition)}) ? ${convertSqliteJsonForNodeRules(condition.consequent)} : ${casesThusFar}`;
    }
    return casesThusFar;
  }, baseCase);
  return `(${cases})`;
};

/**
 * Parse a sqlite json, assuming it's an operation type. Returns null if no suitable operation is found
 * @param json
 * @returns {string}
 */
const convertSqliteJsonOperation = (json, replaceWildcards = false) => {
  if (json.operator === 'not') {
    return `!(${convertSqliteJsonForNodeRules(json.expression)})`;
  }
  switch (json.operation) {
    case 'and':
      return `(${convertSqliteJsonForNodeRules(json.left, replaceWildcards)}) && (${convertSqliteJsonForNodeRules(json.right, replaceWildcards)})`;
    case 'or':
      return `(${convertSqliteJsonForNodeRules(json.left, replaceWildcards)}) || (${convertSqliteJsonForNodeRules(json.right, replaceWildcards)})`;
    case 'between':
      return `(${convertSqliteJsonForNodeRules(json.left)} >= ${convertSqliteJsonForNodeRules(json.right.left)} && ${
        convertSqliteJsonForNodeRules(json.left)} <= ${convertSqliteJsonForNodeRules(json.right.right)})`;
    case 'not between':
      return `!(${convertSqliteJsonForNodeRules(json.left)} >= ${convertSqliteJsonForNodeRules(json.right.left)} && ${
        convertSqliteJsonForNodeRules(json.left)} <= ${convertSqliteJsonForNodeRules(json.right.right)})`;
    case 'in':
      return `[${convertSqliteJsonForNodeRules(json.right)}].includes(${convertSqliteJsonForNodeRules(json.left)})`;
    case 'not in':
      return `![${convertSqliteJsonForNodeRules(json.right)}].includes(${convertSqliteJsonForNodeRules(json.left)})`;
    case '=':
      return `(${convertSqliteJsonForNodeRules(json.left)}) == (${convertSqliteJsonForNodeRules(json.right)})`;
    case 'is':
      return `(${convertSqliteJsonForNodeRules(json.left)}) == (${convertSqliteJsonForNodeRules(json.right)})`;
    case 'is not':
      return `(${convertSqliteJsonForNodeRules(json.left)}) != (${convertSqliteJsonForNodeRules(json.right)})`;
    case '<>':
      return `(${convertSqliteJsonForNodeRules(json.left)}) != (${convertSqliteJsonForNodeRules(json.right)})`;
    case '!=':
      return `(${convertSqliteJsonForNodeRules(json.left)}) != (${convertSqliteJsonForNodeRules(json.right)})`;
    case 'like':
      return `functions.match(${convertSqliteJsonForNodeRules(json.left, true)},${convertSqliteJsonForNodeRules(json.right, true)})`;
    case 'not like':
      return `!functions.match(${convertSqliteJsonForNodeRules(json.left, true)},${convertSqliteJsonForNodeRules(json.right, true)})`;
    case '<':
      return `${convertSqliteJsonForNodeRules(json.left)} < ${convertSqliteJsonForNodeRules(json.right)}`;
    case '<=':
      return `${convertSqliteJsonForNodeRules(json.left)} <= ${convertSqliteJsonForNodeRules(json.right)}`;
    case '>':
      return `${convertSqliteJsonForNodeRules(json.left)} > ${convertSqliteJsonForNodeRules(json.right)}`;
    case '>=':
      return `${convertSqliteJsonForNodeRules(json.left)} >= ${convertSqliteJsonForNodeRules(json.right)}`;
    case '-':
      return `(${convertSqliteJsonForNodeRules(json.left)} - ${convertSqliteJsonForNodeRules(json.right)})`;
    case '+':
      return `(${convertSqliteJsonForNodeRules(json.left)} + ${convertSqliteJsonForNodeRules(json.right)})`;
    case '*':
      return `(${convertSqliteJsonForNodeRules(json.left)} * ${convertSqliteJsonForNodeRules(json.right)})`;
    case '/':
      return `(${convertSqliteJsonForNodeRules(json.left)} / ${convertSqliteJsonForNodeRules(json.right)})`;
    case '%':
      return `(${convertSqliteJsonForNodeRules(json.left)} % ${convertSqliteJsonForNodeRules(json.right)})`;
    default:
      return 'null';
  }
};

/**
 * Utility method that converts a sqlite where to json, then json to javascript conditional
 * @param {string} sqliteCondition
 * @returns a stringified javascript conditional for the given sqlite condition
 */
const convertSqliteForNodeRules = (sqliteCondition) => convertSqliteJsonForNodeRules(
  sqliteCondition.includes('->')
    ? parseSqliteStatementFromArrowSyntax(sqliteCondition)
    : parseSqliteStatement(sqliteCondition),
);
export { convertSqliteForNodeRules };

/**
 * Extracts the variable names from a json expression that adheres to the sqlite parsed json.
 * @param node
 * @param acc
 * @returns {*[]|*}
 */
const extractVariableNamesFromConditionHelper = (node, acc) => {
  if (node.left && node.right) {
    return extractVariableNamesFromConditionHelper(node.left, acc).concat(extractVariableNamesFromConditionHelper(node.right, acc));
  }
  if (node.type === 'function') {
    return node.args.expression.reduce((reduced, paramNode) => extractVariableNamesFromConditionHelper(paramNode, reduced), acc);
  }
  if (node.type === 'identifier' && node.variant === 'column') {
    acc.push(node.name);
  }
  return acc;
};
const extractVariableNamesFromCondition = (node) => [...new Set(extractVariableNamesFromConditionHelper(node, []))];
export { extractVariableNamesFromCondition };
