import {
  Dispatch, SetStateAction, useEffect, useMemo, useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faStethoscope } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Avatar, Grid } from '@mui/material';
import firebase from 'firebase/compat/app';
import { getAuth, User } from 'firebase/auth';
import styled from 'styled-components';
import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import { useTranslation } from 'react-i18next';
import { Scrollbar } from 'smooth-scrollbar-react';
import { Procedure } from '../Procedure';
import { Footer, Column, Row } from '..';
import CompleteVisitPage from './CompleteVisitPage';
import { formatTimestamp } from '../../util/dateTimeUtil';
import { getNextProcedure } from '../../util/visitUtil';
import {
  ProcedureInterface,
  ProcedureStatusInterface,
  VisitInterface,
  SubjectHeaderSiblingStyleProps,
} from '../../types';
import {
  CarryForwardType,
  ProcedureStatus,
  SearchParams,
  VisitModeType,
  isSyntheticQuestionType,
  SubjectHeaderHeight,
  isAnyProcedureMode,
} from '../../enums';
import { fetchProcedureStatuses, useFetchSubjectData } from '../../api/esourceService';
import Legend from './Legend';
import { UnsavedChangesDialog } from '../Procedure/Dialog';
import VisitProgressNotesPage from './VisitProgressNotesPage';
import { ProgressNoteContextProvider } from '../../context/ProgressNoteContext';
import { CompleteVisitContextProvider } from '../../context/CompleteVisitContext';
import generateUids from '../../util/UidUtil';
import { getSkipProcedureStatuses } from '../../util/dataPointUtil';
import { getSkipProcedureStatusTypes } from '../../util/procedureStatusUtil';
import { initializeStickyObserver } from '../../util/scrollUtil';
import '../../config/fullstory';
import SubjectCompleteVisitPage from './SubjectCompleteVisitPage';
import SubjectDetailsPage from './SubjectDetailsPage';

const VisitComponent = styled(Column) <SubjectHeaderSiblingStyleProps>`
  font-family: 'Poppins', 'Poppins', sans-serif;
  background-image: linear-gradient(-37.08448757697341deg, #0d2b3a 52%, #071b26 100%);
  font-weight: 300;

  .procedureStatusIcon {
    margin-right: 8px;
  }

  .VisitHeader {
    color: ${(props) => props.theme.palette.grey[50]};
    padding: 20px 20px 22px 20px;

    h1 {
      margin: 0;
      font-weight: 400;
      font-size: 1.75em;
    }

    .SignOut {
      font-size: 0.8125rem;
    }

    .SignOutLink {
      color: white;
      text-decoration: none;
      cursor: pointer;
      display: inline;
    }

    .SignOutItem {
      justify-content: flex-end;
      align-items: center;
    }

    .AvatarIcon {
      margin-right: 15px;
    }
  }

  .SubjectHeader {
    position: sticky;
    top: -1px;
    z-index: 5;
    height: ${SubjectHeaderHeight.UNSTUCK}px;

    &.SubjectHeaderStuck {
      pointer-events: none;

      .HeaderContent {
        padding: 0;
        height: 40px;
        pointer-events: auto;

        .AppointmentInfo {
          padding: 0;
        }
      }

      .AppointmentTime {
        border-radius: 0;
        min-width: none;
        width: auto;
        height: auto;
        padding: 12px 10px;
      }

      .Segmented {
        font-size: 1rem;
        display: inline-flex!important!important;
        height: auto;
        padding: 0 20px;

        h2 {
          font-size: 1rem;
          display: inline-block;
        }

        span {
          margin-left: 10px;

          &.SegmentedPatientID {
            font-size: 0.75rem;
          }

          &.SegmentedVisitName::before {
            content: "-";
            display: inline-block;
            margin: 0 5px 0 0;
          }
        }

        &.SegmentedUserName {
          margin-left: -5px;
        }
      }

      .Legend {
        padding: 6px 40px;

        button {
          padding: 6px 8px!important;
          font-size: 0.9rem;
          line-height: 0.9rem;
          min-height: 0;

          svg {
            display: none;
          }
        }
      }
    }

    .HeaderContent {
      background-color: #04141c;
      color: ${(props) => props.theme.palette.grey[50]};
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;

      .AppointmentInfo {
        align-items: center;
        padding: 10px 0;
      }
    }

    & > div > svg {
      font-size: 1.5em;
      padding: 10px;
    }

    .AppointmentTime {
      background-color: ${(props) => props.theme.palette.secondary.light};
      border-radius: 50%;
      width: 3.7em;
      min-width: 3.7em;
      height: 3.6em;
      padding-top: 0.1em;
      margin: 0 auto;
      font-size: 1em;
      text-align: center;
      line-height: 1em;
      justify-content: center;
      font-weight: 500;
    }

    .Segmented {
      font-size: 0.65em;
      padding: 0 40px;
      align-items: flex-start;
      justify-content: center;
      height: 43px;

      h2 {
        font-weight: 400;
        margin: 0;
        font-size: 1.25rem;
      }

      span {
        font-size: 1rem;
        font-weight: 300;
      }

      &:not(:last-of-type) {
        border-right: 1px dotted ${(props) => props.theme.palette.grey[50]};
      }

      &.SegmentedUserName {
        margin-left: -20px;

        h2 {
          display: flex;
          align-items: center;
        }

        .fa-stethoscope {
          padding-right: 10px;
          height: 1.25rem;
        }
      }
    }
  }

  .HeaderSpacer {
    height: 40px;
  }

  .Workspace {
    align-items: stretch;
    width: 100%;
  }

  .ProcedureData {
    width: ${(props) => props.width}%;
    .VisitModeBar {
      z-index: 4;
      top: ${(props) => props.subjectHeaderHeight}px;
      position: sticky;
      overflow: unset;
      justify-content: center;
      .VisitMode {
        justify-content: center;
        height: 25px;
        div {
          position: absolute;
          color: ${(props) => props.theme.palette.grey[50]};
          font-weight: 500;
          font-size: .9em;
          padding-top: 2px;
        }
      }
    }
    .ProcedureContent {
      width: 100%;
    }
    .CenterPageBackground {
      background-color: ${(props) => props.theme.palette.grey[50]};
      justify-content: space-between;
    }
    .CenterPagePadding {
        padding: 28px 40px;
    }
  }

  .ProcedureNav {
    font-family: 'Poppins Light', 'Poppins', 'Poppins', sans-serif;
    overflow-y: scroll;
    height: calc(100vh - ${SubjectHeaderHeight.UNSTUCK}px);
    width: 20%;
    color: ${(props) => props.theme.palette.grey[50]};
    text-align: right;
    font-weight: 400;
    font-size: 0.9em;
    position: sticky;
    top: ${(props) => props.subjectHeaderHeight}px;
    z-index: 1;

    & hr {
      margin: 0;
      height: 2px;
      background-color: ${(props) => props.theme.palette.grey[50]};
      border: none;
    }

    & div.NavLink {
      padding: 20px 20px 20px 0;
      cursor: pointer;
      border-bottom: 1px solid rgb(255,255,255,20%);
      font-size: 1.1em;
      font-weight: 400;

      &.Selected {
        background-color: ${(props) => props.theme.palette.grey[50]};
        color: ${(props) => props.theme.palette.h.main};

        .FullyCompletedIcon {
          color: #00ac45;
        }

        .PartiallyCompletedIcon {
          color: #b3a612;
        }
      }

      &:hover:not(.Selected) {
        background-color: ${(props) => props.theme.palette.primary.main};
      }
    }
  }

  .VisitModeBar {
    width: 100%;
    height: 4px;
    &.Sandbox {
      background-color: #95de76;
    }
    &.ReadOnly {
      background-color: #d1a9ff;
    }
  }

  .ProcedureStatusIcon {
    float: left;
    margin-left: 20px;
  }
`;

interface UnsavedChangesDialogInterface {
  isOpen: boolean,
  hasUnsavedChanges: boolean,
  continueHandler?: Function,
}

const _remapProcedures = (procedures: ProcedureInterface[], procedureStatuses: ProcedureStatusInterface[]): ProcedureInterface[] => {
  return procedures
    // Filter out procedures where the questions key is missing or empty
    // for non-configured procedures
    .filter((procedure) => procedure.questions && procedure.questions.length > 0)
    .map((procedure) => ({
      ...procedure,
      procedureId: procedure.procedureId || generateUids(1)[0],
      status: procedureStatuses.find(({ procedureId }) => procedureId === procedure.procedureId)?.status,
      questions: procedure.questions?.map((question) => {
        const { questionId, type, variableName } = question;
        return {
          ...question,
          // Variable names need to be lowercase in the frontend
          variableName: variableName ? variableName.toLowerCase() : variableName,
          // QuestionIds of synthetic questions are equal to their type in the frontend (as opposed to empty)
          questionId: (isSyntheticQuestionType(type)) ? type! : questionId,
        };
      }),
    }));
};

interface VisitProps {
  visitMode: VisitModeType,
  visitConfig: VisitInterface,
  setVisitConfig: Dispatch<SetStateAction<VisitInterface | undefined>>,
}

export default function Visit({ visitMode, visitConfig, setVisitConfig }: VisitProps) {
  const { t, i18n } = useTranslation();
  const {
    readOnly: visitReadOnly, procedures, statuses, name, number,
  } = visitConfig;
  const [unsavedChangesDialogValue, setUnsavedChangesDialogValue] = useState<UnsavedChangesDialogInterface>({ isOpen: false, hasUnsavedChanges: false });
  const [procedureStatuses, setProcedureStatuses] = useState<Array<ProcedureStatusInterface>>([]);
  const [showCompleteVisitPage, setShowCompleteVisitPage] = useState<boolean>(false);
  const [showVisitProgressNotesPage, setShowVisitProgressNotesPage] = useState<boolean>(false);
  const [showSubjectDetailsPage, setShowSubjectDetailsPage] = useState<boolean>(false);
  const [subjectHeaderHeight, setSubjectHeaderHeight] = useState<number>(SubjectHeaderHeight.UNSTUCK);
  const { studyId, subjectId, visitId } = useParams();
  const [searchParams] = useSearchParams();
  const [alertText, setAlertText] = useState<string>(visitReadOnly ? 'You only have read-only access to this visit' : '');
  const { continueHandler = () => { }, isOpen: unsavedChangesDialogOpen, hasUnsavedChanges } = unsavedChangesDialogValue;

  const { subjectData } = useFetchSubjectData(studyId!, subjectId, visitId);

  window.onbeforeunload = () => {
    if (hasUnsavedChanges) {
      // Most modern browsers don't support custom messages anymore. In the off-chance
      // that a browser does, then provide a custom message.
      return 'Are you sure you want to exit';
    }

    return null;
  };

  // TODO: reimplement higher up (datacollection.tsx)
  // store variable names as lower case because sqlite parser changes them to lowercase. Also filter out procedures that were skipped.
  const revisedProcedures: ProcedureInterface[] = useMemo(() => _remapProcedures(procedures || [], procedureStatuses), [procedures, procedureStatuses]);

  const urlSpecifiedProcedureId = searchParams.get(SearchParams.PROCEDURE_ID);

  const firstProcedureId = revisedProcedures[0] ? revisedProcedures[0].procedureId : null;
  const [currentProcedureId, setCurrentProcedureId] = useState<string | null>(
    urlSpecifiedProcedureId ? ((revisedProcedures.find(({ procedureId }) => urlSpecifiedProcedureId === procedureId) || procedures[0]).procedureId) : firstProcedureId,
  );

  const nextProcedure = getNextProcedure(revisedProcedures, procedureStatuses, currentProcedureId);

  /**
   * Initialize an observer to add a sticky class when the subject header gets stuck
   */
  const initializeSubjectHeaderObserver = () => {
    initializeStickyObserver(
      '.SubjectHeader',
      'SubjectHeaderStuck',
      () => setSubjectHeaderHeight(SubjectHeaderHeight.STUCK),
      () => setSubjectHeaderHeight(SubjectHeaderHeight.UNSTUCK),
    );
  };

  const [user, setUser] = useState<User | null | undefined>();
  useEffect(() => {
    if (user === undefined) {
      const { currentUser } = getAuth();
      setUser(currentUser);
    }
    if (!isAnyProcedureMode(visitMode)) {
      initializeSubjectHeaderObserver();
    }
  }, []);

  // Every time we change procedure, refetch all the statuses (users could be concurrently completing the visit)
  useEffect(() => {
    const retrieveProcedureStatuses = async () => {
      const newStatuses = await fetchProcedureStatuses(studyId!, subjectId, visitId);

      // If the new statuses say the procedure we're currently on is skipped, go to the next
      // unskipped procedure automatically.
      const { status } = newStatuses.find(({ procedureId: id }) => currentProcedureId === id) || {};
      if (status && getSkipProcedureStatusTypes().includes(status)) {
        const { procedureId: newProcedureId = null } = revisedProcedures.find(({ procedureId: revisedProcedureId }) => {
          const { status: newProcedureCandidateStatus } = newStatuses.find(({ procedureId: id }) => revisedProcedureId === id) || {};
          return !newProcedureCandidateStatus || !getSkipProcedureStatusTypes().includes(newProcedureCandidateStatus);
        }) || {};
        setCurrentProcedureId(newProcedureId);
      }

      setProcedureStatuses(newStatuses);
    };

    setUnsavedChangesDialogValue((prevState) => ({ ...prevState, hasUnsavedChanges: false }));
    retrieveProcedureStatuses();
  }, [currentProcedureId, showCompleteVisitPage]);

  // TODO: commenting this out for now because this behavior was broken on QA and is not a priority
  // const listenToScroll = () => {
  //   const visitHeader = document.getElementById('visit-header');
  //   const visitHeaderHeight = visitHeader ? visitHeader.scrollHeight : 75;
  //   const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  //   if (winScroll > visitHeaderHeight) {
  //     setHeaderLocked(true);
  //   } else {
  //     setHeaderLocked(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('scroll', listenToScroll);
  //   return () => window.removeEventListener('scroll', listenToScroll);
  // }, []);

  const goToProcedure = (procedureId: string) => {
    setCurrentProcedureId(procedureId);
    setShowCompleteVisitPage(false);
    setShowVisitProgressNotesPage(false);
    setShowSubjectDetailsPage(false);
    setUnsavedChangesDialogValue({ isOpen: false, hasUnsavedChanges: false });
    window.scrollTo(0, 0);
  };

  const goToCompleteVisit = () => {
    setCurrentProcedureId(null);
    setShowCompleteVisitPage(true);
    setShowVisitProgressNotesPage(false);
    setShowSubjectDetailsPage(false);
    setUnsavedChangesDialogValue({ isOpen: false, hasUnsavedChanges: false });
    window.scrollTo(0, 0);
  };

  const goToProgressNotes = () => {
    setCurrentProcedureId(null);
    setShowCompleteVisitPage(false);
    setShowVisitProgressNotesPage(true);
    setShowSubjectDetailsPage(false);
    setUnsavedChangesDialogValue({ isOpen: false, hasUnsavedChanges: false });
    window.scrollTo(0, 0);
  };

  const goToSubjectDetails = () => {
    setCurrentProcedureId(null);
    setShowCompleteVisitPage(false);
    setShowVisitProgressNotesPage(false);
    setShowSubjectDetailsPage(true);
    setUnsavedChangesDialogValue({ isOpen: false, hasUnsavedChanges: false });
    window.scrollTo(0, 0);
  };

  // If there are no procedures, we should start at the complete visit page
  useEffect(() => {
    if (!currentProcedureId) {
      goToCompleteVisit();
    }
  }, []);

  const handleSelectProcedure = ({ procedureId }: ProcedureInterface) => {
    if (hasUnsavedChanges) {
      setUnsavedChangesDialogValue((prevState) => ({ ...prevState, continueHandler: () => { goToProcedure(procedureId); }, isOpen: true }));
      return;
    }
    goToProcedure(procedureId);
  };

  const handleSelectCompleteVisit = () => {
    if (hasUnsavedChanges) {
      setUnsavedChangesDialogValue((prevState) => ({ ...prevState, continueHandler: () => { goToCompleteVisit(); }, isOpen: true }));
      return;
    }
    goToCompleteVisit();
  };

  const handleUnsavedChanges = (callback: () => void) => {
    if (hasUnsavedChanges) {
      setUnsavedChangesDialogValue((prevState) => ({
        ...prevState,
        continueHandler: () => {
          callback();
        },
        isOpen: true,
      }));
      return;
    }
    callback();
  };

  const handleSelectProgressNotes = () => {
    handleUnsavedChanges(goToProgressNotes);
  };

  const handleSelectSubjectDetails = () => {
    handleUnsavedChanges(goToSubjectDetails);
  };

  const readOnly = visitMode === VisitModeType.READ_ONLY || visitMode === VisitModeType.SANDBOX_READ_ONLY;

  const getCenterPageComponent = () => {
    if (!subjectData) {
      return null;
    }

    if (showCompleteVisitPage) {
      if (VisitModeType.SUBJECT === visitMode) {
        return (
          <SubjectCompleteVisitPage />
        );
      }

      return (
        <ProgressNoteContextProvider visitMode={visitMode} subjectData={subjectData} visitConfig={visitConfig}>
          <CompleteVisitContextProvider
            statuses={statuses}
            subjectData={subjectData}
            visitMode={visitMode}
            visitConfig={visitConfig}
            skipProcedureStatuses={getSkipProcedureStatuses(procedureStatuses)}
          >
            <CompleteVisitPage
              readOnly={readOnly}
              incompleteVisitProcedureNames={revisedProcedures
                .filter(({ procedureId }) => {
                  const { status } = procedureStatuses.find(({ procedureId: statusProcedureId }) => statusProcedureId === procedureId) || {};
                  return !status || !([ProcedureStatus.COMPLETED, ...getSkipProcedureStatusTypes()].includes(status));
                })
                .map(({ name: incompleteProcedureName }) => incompleteProcedureName)}
              incompleteSubjectProcedureNames={revisedProcedures
                .filter(({ carryForwardType, procedureId }) => {
                  if (carryForwardType !== CarryForwardType.PERMANENT) {
                    return false;
                  }

                  const { subjectProcedureStatus: status } = procedureStatuses.find(({ procedureId: statusProcedureId }) => statusProcedureId === procedureId) || {};
                  return status !== ProcedureStatus.COMPLETED;
                })
                .map(({ name: incompleteProcedureName }) => incompleteProcedureName)}
            />
          </CompleteVisitContextProvider>
        </ProgressNoteContextProvider>
      );
    }

    if (showVisitProgressNotesPage) {
      return (
        <ProgressNoteContextProvider visitMode={visitMode} subjectData={subjectData} visitConfig={visitConfig}>
          <VisitProgressNotesPage readOnly={readOnly} subjectHeaderHeight={subjectHeaderHeight} timeZone={subjectData.timeZone} />
        </ProgressNoteContextProvider>
      );
    }

    if (showSubjectDetailsPage) {
      return <SubjectDetailsPage subjectHeaderHeight={subjectHeaderHeight} subjectData={subjectData} studyId={studyId || ''} visitId={visitId} />;
    }

    if (currentProcedureId) {
      return (
        <Procedure
          setProcedureStatuses={setProcedureStatuses}
          initialProcedureStatus={procedureStatuses.find(({ procedureId: statusProcedureId }) => statusProcedureId === currentProcedureId)?.status || ProcedureStatus.NOT_STARTED}
          setHasUnsavedChanges={(newValue: boolean) => {
            if (hasUnsavedChanges !== newValue) {
              setUnsavedChangesDialogValue({ ...unsavedChangesDialogValue, hasUnsavedChanges: newValue });
            }
          }}
          subjectData={subjectData}
          setShowCompleteVisitPage={setShowCompleteVisitPage}
          procedureId={currentProcedureId}
          setProcedureId={setCurrentProcedureId}
          key={currentProcedureId}
          visitMode={visitMode}
          nextProcedureId={nextProcedure ? nextProcedure.procedureId : undefined}
          visitConfig={{ ...visitConfig, procedures: revisedProcedures }}
          setVisitConfig={setVisitConfig}
          subjectHeaderHeight={subjectHeaderHeight}
        />
      );
    }
    return null;
  };

  return (
    <VisitComponent subjectHeaderHeight={subjectHeaderHeight} width={isAnyProcedureMode(visitMode) ? 100 : 80}>
      {alertText && (
        <CrioAlertDialog
          type="Info"
          open={!!alertText}
          onClose={() => {
            setAlertText('');
          }}
          fullWidth={false}
          disablePortal
          sx={{ width: '50%', margin: 'auto' }}
        >
          <div>{alertText}</div>
        </CrioAlertDialog>
      )}
      {!isAnyProcedureMode(visitMode) && (
        <Row className="VisitHeader" id="visit-header">
          <Grid container>
            <Grid item xs={5}>
              <h1>
                {visitId ? (
                  <>
                    {subjectData?.protocolNumber}
                  </>
                ) : (<>{t('Common.Logs')}</>)}
              </h1>
            </Grid>
            <Grid className="SignOutItem" item xs={7} container>
              <Avatar className="AvatarIcon" src={user?.photoURL || ''} alt={user?.displayName || ''} />
              <h1 className="SignOut">
                {t('Visit.Visit.Welcome')}
                {' '}
                {user?.displayName || ''}
                {' '}
                {
                  /*
                    Hide the Sign Out link for the time being.
                    There is still an ongoing discussion regarding what Sign Out should do,
                    e.g.: sign out of eSource SPA or also site-app, etc.
                  */
                  false && (
                    <div
                      role="button"
                      title={t('Visit.Visit.Sign Out')}
                      tabIndex={0}
                      className="SignOutLink"
                      onClick={() => { firebase.auth().signOut(); }}
                      onKeyDown={() => { firebase.auth().signOut(); }}
                    >
                      {t('Visit.Visit.sign out')}
                    </div>
                  )
                }
              </h1>
            </Grid>
          </Grid>
        </Row>
      )}
      {!isAnyProcedureMode(visitMode) && (
        <>
          <Column className="SubjectHeader">
            <Row className="HeaderContent">
              <Row className="AppointmentInfo">
                {visitId && (
                  <Column className="AppointmentTime">
                    {subjectData?.startTime || formatTimestamp(Date.now(), i18n.language, subjectData?.timeZone)}
                  </Column>
                )}
                <Column className="Segmented">
                  <h2>{subjectData?.subjectName}</h2>
                  <span className="SegmentedPatientID">{subjectData?.patientId}</span>
                </Column>
                {visitId && (
                  <Column className="Segmented">
                    <h2>
                      {t('Visit.Visit.Header')}
                      &nbsp;
                      {number}
                    </h2>
                    <span className="SegmentedVisitName">{name}</span>
                  </Column>
                )}
                {!(VisitModeType.SUBJECT === visitMode) && (
                  <Column className="Segmented SegmentedUserName">
                    <h2>
                      <FontAwesomeIcon icon={faStethoscope as IconProp} />
                      {user?.displayName || ''}
                    </h2>
                  </Column>
                )}
              </Row>
              {!(VisitModeType.SUBJECT === visitMode) && (
                <Legend />
              )}
            </Row>
            {/* {headerLocked && <div className={`VisitModeBar ${visitMode === VisitModeType.READ_ONLY ? 'ReadOnly' : 'Sandbox'}`} />} */}
          </Column>
          <Row className="HeaderSpacer" />
        </>
      )}
      <Row className="Workspace" id="workspace">
        {!isAnyProcedureMode(visitMode) && (
          <Scrollbar>
            <Column className="ProcedureNav">
              <div>
                {
                  revisedProcedures!.map((procedure) => {
                    const { status, subjectProcedureStatus } = procedureStatuses.find(({ procedureId }) => procedureId === procedure.procedureId) || {};
                    const actualStatus = visitId ? status || ProcedureStatus.NOT_STARTED : subjectProcedureStatus;

                    return (
                      status && getSkipProcedureStatusTypes().includes(status)
                        ? (
                          <div
                            key={procedure.procedureId}
                            style={{ display: 'none' }}
                          />
                        ) : (
                          <div
                            role="button"
                            tabIndex={0}
                            key={procedure.procedureId}
                            data-testid={currentProcedureId && procedure.procedureId === currentProcedureId ? 'currentProcedure' : ''}
                            className={currentProcedureId && procedure.procedureId === currentProcedureId ? 'Selected NavLink' : 'NavLink'}
                            onClick={() => handleSelectProcedure(procedure)}
                            onKeyDown={() => handleSelectProcedure(procedure)}
                          >
                            <div className="ProcedureStatusIcon">
                              {actualStatus === ProcedureStatus.PARTIALLY_COMPLETED && (<FontAwesomeIcon className="procedureStatusIcon PartiallyCompletedIcon" icon={faExclamationCircle as IconProp} color="#ffffae" size="xl" title={t('Visit.Column.Incomplete Procedure')} />)}
                              {actualStatus === ProcedureStatus.COMPLETED && (<FontAwesomeIcon className="procedureStatusIcon FullyCompletedIcon" icon={faCheck as IconProp} color="#00ff66" size="xl" title={t('Visit.Column.Completed Procedure')} />)}
                            </div>
                            {procedure.name}
                          </div>
                        )
                    );
                  })
                }
                <hr />
                {visitId && !(VisitModeType.SUBJECT === visitMode) && (
                  <>
                    <div
                      role="button"
                      tabIndex={0}
                      className={showCompleteVisitPage ? 'Selected NavLink' : 'NavLink'}
                      onClick={handleSelectCompleteVisit}
                      onKeyDown={handleSelectCompleteVisit}
                    >
                      {t('Visit.Complete Visit.Header')}
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      className={showVisitProgressNotesPage ? 'Selected NavLink' : 'NavLink'}
                      onClick={handleSelectProgressNotes}
                      onKeyDown={handleSelectProgressNotes}
                    >
                      {t('Visit.Progress Notes.Header')}
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      className={showSubjectDetailsPage ? 'Selected NavLink' : 'NavLink'}
                      onClick={handleSelectSubjectDetails}
                      onKeyDown={handleSelectSubjectDetails}
                    >
                      {t('Visit.Subject Details.Header')}
                    </div>
                  </>
                )}
              </div>
            </Column>
          </Scrollbar>
        )}
        <Column className="ProcedureData">
          {getCenterPageComponent()}
          {!isAnyProcedureMode(visitMode) && (<Footer />)}
        </Column>
      </Row>
      {unsavedChangesDialogOpen
        && (
          <UnsavedChangesDialog
            continueHandler={continueHandler}
            closeHandler={() => setUnsavedChangesDialogValue((prevState) => ({ ...prevState, isOpen: false }))}
            isOpen={unsavedChangesDialogOpen}
          />
        )}
    </VisitComponent>
  );
}
