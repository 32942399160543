import styled from 'styled-components';
import { CrioTooltip } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import { Fade } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyeArrow, faHeart } from '@fortawesome/pro-solid-svg-icons';
import {useTranslation} from "react-i18next";

const StyleIcon = styled('image')`
  .Tooltip {
    margin-left: 16px;
    margin-right: -32px;
  }

  .Bullseye {
    font-size: 16px;
    color: rgb(102, 102, 102);
  }

  .Heart {
    font-size: 16px;
    color: rgb(255, 153, 51);
  }
`;

type HeartAndTargetIconsProps = {
  visitIsPreview?: boolean;
  core?: boolean;
  siteAdded?: boolean;
  procedureQuestion?: boolean;
};

/**
 * Handles the rendering of the target icons for core questions and
 * the heart icons for site-added questions.
 * @param visitIsPreview represents whether the visit is in preview mode
 * @param core represents if the question is a core question or not
 * @param siteAdded represents if the question is a site-added question
 * @param procedureQuestion represents if the questions is a procedure question or a grid type question
 * @constructor
 */
function HeartAndTargetIcons({
  visitIsPreview, core, siteAdded, procedureQuestion,
}:HeartAndTargetIconsProps) {
  const { t } = useTranslation();
  return (
    <>
      {visitIsPreview && core && (
      <StyleIcon>
        <CrioTooltip
          className={!procedureQuestion ? 'Tooltip' : undefined}
          title={t('Icon.Core Question')}
          placement="top"
          arrow
          slots={{ transition: Fade }}
          type="HOVER"
        >
          <StyleIcon>
            <FontAwesomeIcon className="Bullseye" icon={faBullseyeArrow} />
          </StyleIcon>
        </CrioTooltip>
      </StyleIcon>
      )}
      {visitIsPreview && siteAdded && (
      <StyleIcon>
        <CrioTooltip
          className={!procedureQuestion ? 'Tooltip' : undefined}
          title={t('Icon.Custom, Site-Added')}
          placement="top"
          arrow
          slots={{ transition: Fade }}
          type="HOVER"
        >
          <StyleIcon>
            <FontAwesomeIcon className="Heart" icon={faHeart} />
          </StyleIcon>
        </CrioTooltip>
      </StyleIcon>
      )}
    </>
  );
}

HeartAndTargetIcons.defaultProps = {
  visitIsPreview: false,
  siteAdded: false,
  core: false,
  procedureQuestion: false,
};

export default HeartAndTargetIcons;
