import { CircularProgress, styled } from '@mui/material';

const StyledSpinner = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    margin-top: 40px;
    align-self: center;
    color: ${(props) => props.theme.palette.grey[500]};
  }
`;

export interface SmallLoadingSpinnerProps {
  className?: string;
}

export default function SmallLoadingSpinner({ className }: SmallLoadingSpinnerProps) {
  return <StyledSpinner className={className} data-testid="smallLoadingSpinner" />;
}

SmallLoadingSpinner.defaultProps = {
  className: '',
};
