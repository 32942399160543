import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogContent, DialogContentText, Divider, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QuestionAnswer } from '../../../types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import { AnswerType } from '../../../enums';

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 700px;
    border-radius: 5px;
    padding: 1em;
  }

  & .MuiDialogContent-root {
    padding: 0;
  }

  & .MuiDialogContentText-root {
    margin: 1em 0;
    color: ${(props) => props.theme.palette.grey[900]};
  }

  & .MuiDialogActions-root {
    padding: 0;
    padding-top: 1.5em;
    justify-content: flex-start;
  }
`;

const Title = styled('div')`
  color: ${(props) => props.theme.palette.grey[900]};
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.palette.grey[600]};
  font-size: 1.5em;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.grey[700]};
  }
`;

const SideBySide = styled(Stack)`
  background-color: ${props => props.theme.palette.grey[300]};

  & .SideBySide-value {
    padding: 1em;
    flex-grow: 1;

    h6 {
      margin-bottom: 1em;
    }
  }
`;

type DisplayAnswerProps = {
  label: string;
  answer: QuestionAnswer;
  answerType: AnswerType;
}
function DisplayAnswer({
  label,
  answer,
  answerType
}: DisplayAnswerProps) {

  const isMultiSelect = answerType === AnswerType.BULLET;

  console.debug('DisplayAnswer', {label, answer, answerType})
  
  return (<Stack className='SideBySide-value'>
    <Typography variant='subtitle1'>
      {label}
    </Typography>
    {!isMultiSelect && <Typography>{answer}</Typography>}
    {isMultiSelect && 
      <Typography component="ul" variant="body2">
      {(answer as string).split('\n').map((item) => (<li key={item}>{item}</li>))}
      </Typography>
    }
  </Stack>)
}

type ConfirmUpdateAnswerDialogProps = {
  questionText: string,
  currentAnswer: QuestionAnswer;
  updatedAnswer: QuestionAnswer;
  answerType: AnswerType;
  open?: boolean;
  onClose: () => void;
  handleUpdate: () => void;
}
function ConfirmUpdateAnswerDialog({
  questionText,
  currentAnswer: currentAnswer,
  updatedAnswer: updatedAnswer,
  answerType,
  open = true,
  onClose,
  handleUpdate
}: ConfirmUpdateAnswerDialogProps
) {
  const { t } = useTranslation();
  return (<StyledDialog
    open={open}
    onClose={onClose}
  >
    <Title>
      <Typography variant="h6">
        {t('Procedure.Question.Reference.Confirm update')}
      </Typography>
      <CloseButton icon={faTimes} onClick={onClose} aria-label="close" />
    </Title>
    <DialogContent>
      <DialogContentText>
        {questionText}
      </DialogContentText>
      <SideBySide direction="row">
        <DisplayAnswer
          label={t('Procedure.Question.Reference.Current value')}
          answer={currentAnswer}
          answerType={answerType}
        />
        <Divider orientation='vertical' flexItem />
        <DisplayAnswer
          label={t('Procedure.Question.Reference.Updated value')}
          answer={updatedAnswer}
          answerType={answerType}
        />
      </SideBySide>
    </DialogContent>
    <DialogActions>
      <CrioButton colorTheme='Wizard' onClick={handleUpdate}>
        {t('Procedure.Question.Reference.Update')}
      </CrioButton>
      <CrioButton colorTheme='Secondary' onClick={onClose}>
        {t('Common.Cancel')}
      </CrioButton>
    </DialogActions>
  </StyledDialog>);
}

export default ConfirmUpdateAnswerDialog;
