import styled from 'styled-components';
import { Interweave } from 'interweave';
import { memo } from 'react';
import CalculationQuestion from './CalculationQuestion';
import DateQuestion from './DateQuestion';
import FreeTextQuestion from './FreeTextQuestion';
import FreeNumberQuestion from './FreeNumberQuestion';
import MultiSelectQuestion from './MultiSelectQuestion';
import SingleSelectQuestion from './SingleSelectQuestion';
import NotDoneQuestion from './NotDoneQuestion';
import { InstructionContent, RecordQuestion } from '../../../types';
import InstructionText from '../InstructionText';
import { Row } from '../..';
import { NormalQuestionType, QuestionFixedAnswerType, VisitModeType } from '../../../enums';
import DropdownQuestion from './DropdownQuestion';
import ReferenceQuestion from './ReferenceQuestion';
import HeartAndTargetIcons from '../HeartAndTargetIcons';

const VariableName = styled('span')`
  display: block;
  color: ${(props) => props.theme.palette.grey[600]};
  font-size: 0.8em;
  padding-bottom: 15px;
`;

interface QuestionComponentProps {
  showingVariableName?: boolean,
}

const QuestionComponent = styled(Row) <QuestionComponentProps>`
  font-family: 'Poppins', 'Poppins', sans-serif;
  align-items: flex-start;
  padding: 30px 0;
  font-weight: 300;

  :not(:last-of-type) {
    border-bottom: 1px dotted ${(props) => props.theme.palette.grey[500]};
  }

  .Instruction {
    max-width: 25%;
    p.MuiTypography-root {
      font-size: 0.9em;
    }
  }

  &>div {
    flex-direction: column;
    width: 100%;
  }

  div.QuestionText {
    font-size: 1.1em;
    padding-bottom: ${(props) => (props.showingVariableName ? '0' : '15px')};
    font-weight: 400;
    color: ${(props) => props.theme.palette.grey[900]};
  }

  .MuiTypography-root.MuiFormControlLabel-label {
    font-size: 1em;
    color: #666666;
  }

  .MuiFormControlLabel-root .MuiButtonBase-root {
    margin-right: 15px;
  }

  .CalculatedValue, .ReferencedValue {
    color: #cccccc;
    font-size: 0.9em;
  }

  .RadioGroup {
    padding-left: 4px;
  }
`;

/**
 * Generic ProcedureQuestion component that handles rendering the different question formats.
 * @param props
 * @returns
 */
/*
  One misconception is that components only rerender if the props provided to it change in a meaningful way.
  This is incorrect. If the parent rerenders, then by default the ENTIRE tree underneath it rerenders as well.
  We can fix this to only rerender when props change by using memo. The tree rerender is stopped short here
  and this part (and children) will only rerender if something inside props actually changes.
*/
const ProcedureQuestion = memo((props: RecordQuestion) => {
  const {
    type, formatType, questionText, hint, isLoading, dataPoint, readOnly, variableName, showVariableName, criteria, visitMode, templateLevel, versionId,
  } = props;
  const instructionContent: InstructionContent = { body: hint };

  if (!dataPoint) return null;
  if (dataPoint.is_disabled) return null;
  if (isLoading) return null;
  const { answer } = dataPoint;
  const isNotDone = (answer === QuestionFixedAnswerType.NOT_DONE);
  const isReference = type == NormalQuestionType.REFERENCE;

  if (isReference) {
    instructionContent.questionCriteria = criteria!;
  }

  return (
    <QuestionComponent showingVariableName={showVariableName} data-testid={`question-${variableName}`} className="Question">
      <div>
        <div>
          <HeartAndTargetIcons
            visitIsPreview={visitMode === VisitModeType.PROCEDURE_PREVIEW}
            core={templateLevel === 'IN_TEMPLATE_CORE'}
            siteAdded={templateLevel === 'NOT_FROM_TEMPLATE' && versionId !== undefined}
            procedureQuestion
          />
        </div>
        <div className="QuestionText"><Interweave content={questionText} /></div>
        {showVariableName && <VariableName>{variableName}</VariableName>}
        {isNotDone
            && <NotDoneQuestion {...props} />}
        {!isNotDone && type === NormalQuestionType.CALCULATION
            && <CalculationQuestion {...props} />}
        {!isNotDone && type === NormalQuestionType.FREE_ENTRY && formatType && formatType.startsWith('TEXT')
            && <FreeTextQuestion {...props} />}
        {!isNotDone && type === NormalQuestionType.FREE_ENTRY && formatType && formatType.startsWith('NUMBER')
            && <FreeNumberQuestion {...props} />}
        {!isNotDone && type === NormalQuestionType.FREE_ENTRY && formatType && formatType.startsWith('DATE')
            && <DateQuestion {...props} />}
        {!isNotDone && type === NormalQuestionType.MULTI_SELECT
            && <MultiSelectQuestion {...props} />}
        {!isNotDone && type === NormalQuestionType.SINGLE_SELECT && !formatType
            && <SingleSelectQuestion {...props} />}
        {!isNotDone && type === NormalQuestionType.SINGLE_SELECT && formatType && formatType.startsWith('DROPDOWN')
            && <DropdownQuestion {...props} />}
        {!isNotDone && type == NormalQuestionType.REFERENCE
            && <ReferenceQuestion {...props} />}
      </div>
      {(hint || isReference)
          && <InstructionText instructions={instructionContent} allowSkip={false} readOnly={!!readOnly} truncate />}
    </QuestionComponent>
  );
});

export default ProcedureQuestion;
