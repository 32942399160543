import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CrioDropdown from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioDropdown';
import { Answer, RecordQuestion } from '../../../types';
import { getAnswerCommentsJson, getAnswerOptionsDataList, parseAnswerCommentJson } from '../../../util/answerComments';
import { AnswerType } from '../../../enums';
import DataListQuestion from './DataListQuestion';

/* WARNING
  We definitely do not want to get anything from the context directly at the question-level.
  Context value, when updated, triggers a blind rerender of EVERYTHING that makes use of it
  via useContext. Instead, things like handleAnswerChange and the dataPoints nested inside
  records are provided as props, therefore dodging the expensive context rerenders.
*/
function DropdownQuestion(props: RecordQuestion) {
  const {
    variableName, dataPoint, handleAnswerChange, answerOptions, recordId, questionId, readOnly,
  } = props;
  const { t } = useTranslation();
  const { studyId } = useParams();
  const { answer, answer_code: answerCode, answer_comment: answerComment } = dataPoint || {};
  if (dataPoint) {
    dataPoint.answer_type = AnswerType.TEXT;
  }

  const commentRequired = answerOptions?.find(({ text }) => text === answer)?.commentRequired || false;

  // Get the Data List External ID for the currently selected answer, if any
  const answerOptionsDataList = getAnswerOptionsDataList(answerOptions, answer);

  /**
   *  * Handle the updating of an Answer Option
   * This will also update the Data Point and potentially trigger Procedure Logic
   * Thus this should be called any time the new Answer/Answer Comment is "finalized"
   * @param newSelection The new option selected
   * @param newComment The new answer comment
   */
  const handleDatapointChange = (newSelection: string | undefined, newComment: string | undefined) => {
    const newAnswerOption: Answer | undefined = newSelection !== undefined ? answerOptions?.find(({ text }) => text === newSelection) : undefined;
    const { text: newAnswer, answerCode: newAnswerCode } = newAnswerOption || { text: '', answerCode: '' };
    const newDataPoint = {
      ...dataPoint,
      answer_comment: getAnswerCommentsJson(newComment, newSelection || answer),
      answer: newSelection === undefined ? answer : newAnswer,
      answer_code: newSelection === undefined ? answerCode : newAnswerCode,
    };

    handleAnswerChange({
      variableName,
      newDataPoint,
      recordId,
      runRules: newComment === undefined,
      questionId,
      selectionValueChange: newSelection,
    });
  };

  return (
    <>
      <CrioDropdown
        data-testid="dropdown"
        disabled={readOnly}
        value={answer || ''}
        options={answerOptions!.map(({ text }) => text)}
        placeholder="- Select Option -"
        onChange={(e) => {
          const { target: { value } } = e;
          handleDatapointChange(value as string, undefined);
        }}
        deselectable
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          }
        }}
      />
      {commentRequired && (
        <>
          <br />
          <br />
          <DataListQuestion
            multiline
            variableName={variableName!}
            studyId={studyId!}
            dataListExternalId={answerOptionsDataList?.externalId}
            restrictedToDataList={answerOptionsDataList?.isRestrictedToDataList}
            readOnly={readOnly}
            value={parseAnswerCommentJson(answerComment, answer) || ''}
            placeholder={t('Common.Enter Comment')}
            onBlur={(value: string) => handleDatapointChange(undefined, value)}
          />
        </>
      )}
    </>

  );
}

export default DropdownQuestion;
